import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Box, Inline, Stack, Text, xcss } from '@atlaskit/primitives';
import Link from '@atlaskit/link';
import SectionMessage from '@atlaskit/section-message';

import { useAiBulkNavigationGotoPage } from './providers/AiBulkNavigationContextProvider';
import { useBulkCreateContext, useGetIssueType } from './providers/BulkCreateContextProvider';
import { BulkCreateIssueCard } from './BulkCreateIssueCard';
import { messages } from './messages';
import { BulkCreateErrorSectionMessage } from './BulkCreateErrorSectionMessage';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';

const bodyStyles = xcss({
	flexGrow: 1,
	overflowY: 'auto',
});

// Used for both AI and non-AI bulk create
export const BulkCreateBody = () => {
	const goToPage = useAiBulkNavigationGotoPage();

	const [{ aiBulkIssuesList, currentJiraSite, currentJiraProject, issueTypeHasRequiredFields }] =
		useBulkCreateContext();
	const { logAiResultViewed } = useBulkCreateLoggingEvents();

	const issueType = useGetIssueType();

	const onConfigureClick = useCallback(
		(event) => {
			event.preventDefault();
			void goToPage('configure');
		},
		[goToPage],
	);

	useEffect(() => {
		// TODO: Change this before rolling out confluence_ai_bulk_issue_creation_handle_chunks.
		if (aiBulkIssuesList.length > 0) {
			logAiResultViewed(aiBulkIssuesList.length);
		}
	}, [aiBulkIssuesList, logAiResultViewed]);

	return (
		<Box padding="space.200" paddingBlockStart="space.100" xcss={bodyStyles}>
			<Stack space="space.200">
				<Stack space="space.100">
					<BulkCreateErrorSectionMessage />
					{currentJiraSite && (
						<Inline space="space.050">
							<Text color="color.text.subtlest" weight="semibold">
								<FormattedMessage {...messages.jiraSiteHeading} />
							</Text>
							<Text color="color.text.subtlest">{currentJiraSite.displayName}</Text>
						</Inline>
					)}
					{currentJiraProject && (
						<Inline space="space.050">
							<Text color="color.text.subtlest" weight="semibold">
								<FormattedMessage {...messages.jiraProjectHeading} />
							</Text>
							<Text color="color.text.subtlest">{currentJiraProject.name}</Text>
						</Inline>
					)}
					<Text size="small" weight="bold">
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid -- This is a valid use of <Link> as it is used for navigation */}
						<Link href="#" testId="bulk-create-configure-button" onClick={onConfigureClick}>
							Configure
						</Link>
					</Text>
					{issueTypeHasRequiredFields && (
						<SectionMessage title={messages.requiredFieldsSectionMessageTitle.defaultMessage}>
							<Text>
								<FormattedMessage {...messages.requiredFieldsSectionMessageDescription} />
							</Text>
						</SectionMessage>
					)}
				</Stack>
				<Stack space="space.100">
					{aiBulkIssuesList.map((issue) => (
						<BulkCreateIssueCard key={issue.id} issue={issue} issueType={issueType} />
					))}
				</Stack>
			</Stack>
		</Box>
	);
};
