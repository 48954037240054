import { useCallback } from 'react';

import {
	useBulkCreateContext,
	useGetIssueType,
	useSetSingleIssuesCreated,
} from '../providers/BulkCreateContextProvider';
import type { CreateIssueResponse } from '../__types__/apiUtils';

import { useCreateBulkIssues } from './useBulkCreateIssues';

export const useCreateIssue = () => {
	const [{ aiBulkIssuesList, currentJiraProject }] = useBulkCreateContext();
	const { createBulkIssuesHandler, loading, response } = useCreateBulkIssues();
	const issueType = useGetIssueType();
	const setSingleIssuesCreated = useSetSingleIssuesCreated();

	const createIssueHandler = useCallback(
		async ({
			issueId,
			onComplete,
			onFailure,
		}: {
			issueId: string;
			onComplete: (response: CreateIssueResponse) => void;
			onFailure: (error: Error | unknown) => void;
		}) => {
			const issue = aiBulkIssuesList.find((item) => item.id === issueId);
			if (!issue || !currentJiraProject) {
				return;
			}

			void createBulkIssuesHandler({
				issueBody: {
					issueUpdates: [
						{
							fields: {
								description: issue.description,
								issuetype: { id: issueType.id },
								project: { id: currentJiraProject.id },
								summary: issue.summary,
							},
						},
					],
				},
				onComplete: (bulkCreateResponse) => {
					const result = bulkCreateResponse.issues?.[0];
					if (result) {
						const issueCreated = { ...result, summary: issue.summary };
						void setSingleIssuesCreated(issueCreated);
						onComplete(issueCreated);
					}
				},
				onFailure,
			});
		},
		[
			aiBulkIssuesList,
			currentJiraProject,
			createBulkIssuesHandler,
			issueType.id,
			setSingleIssuesCreated,
		],
	);

	return { createIssueHandler, loading, response };
};
