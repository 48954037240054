import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl-next';

import { Inline, xcss } from '@atlaskit/primitives';
import Button from '@atlaskit/button/new';

import { useSetErrorMessage } from './providers/AiBulkNavigationContextProvider';
import { useCreateAllIssues } from './utils/useCreateAllIssues';
import { useBulkCreateContext } from './providers/BulkCreateContextProvider';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';
import { messages } from './messages';
import { insertContentAtSelectionEnd } from './utils/issueInsertionUtils';
import { useGetIssueInsertionActions } from './utils/useGetIssueInsertionActions';

const buttonGroupStyles = xcss({
	paddingTop: 'space.050',
});

// Bulk create button group - used for both AI and non-AI bulk create
export const BulkCreateButtonGroup = ({
	onClose,
	onCreateAll,
}: {
	onClose: () => void;
	onCreateAll: () => void;
}) => {
	const { createAllIssuesHandler, loading: isCreateAllLoading } = useCreateAllIssues();
	const [
		{
			aiBulkIssuesList,
			issueTypeHasRequiredFields,
			insertStorageFragmentRequestPayload,
			singleIssuesCreated,
		},
		{ removeAiBulkIssueList },
	] = useBulkCreateContext();
	const {
		triggerMultipleIssuesCreatedFlag,
		triggerSingleIssueInsertLinkFailureFlag,
		triggerMultipleIssuesInsertLinkFailureFlag,
	} = useBulkCreateFlags();
	const setErrorMessage = useSetErrorMessage();
	const { logCreateAllClick, logMultipleIssueCreateSuccess, logAiIssueCreationFailed } =
		useBulkCreateLoggingEvents();
	const { constructAnchorLinkToHeading, constructXmlModification } = useGetIssueInsertionActions();

	const reloadOnClick = useCallback(() => {
		// eslint-disable-next-line no-restricted-syntax
		window.location.href = constructAnchorLinkToHeading();
		window.location.reload();
	}, [constructAnchorLinkToHeading]);

	const isCreateAllDisabled = aiBulkIssuesList.length === 0 || issueTypeHasRequiredFields;

	const onCreateAllClick = useCallback(() => {
		logCreateAllClick();
		setErrorMessage(null);
		void createAllIssuesHandler({
			onComplete: ({ createdIssues, createdIssueIDs, failedIssueIDs }) => {
				// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
				logMultipleIssueCreateSuccess(createdIssueIDs.length, true);
				let showReloadAction = false;
				if (insertStorageFragmentRequestPayload) {
					insertStorageFragmentRequestPayload.xmlModification = constructXmlModification(
						[...createdIssues, ...singleIssuesCreated],
						insertStorageFragmentRequestPayload,
					);
					insertContentAtSelectionEnd(insertStorageFragmentRequestPayload)
						.then((data) => {
							// There is a case where the API response is OK, but the response data is false.
							// When this happens, the backend has trouble inserting the ticket to the page and we should show an error toast.
							if (data === false) {
								throw new Error('Failed to insert storage fragment');
							}
						})
						.then(() => {
							showReloadAction = true;
						})
						.catch((_error) => {
							if (createdIssues.length === 1) {
								triggerSingleIssueInsertLinkFailureFlag({
									issueKey: createdIssues[0].key,
									summary: createdIssues[0].summary,
								});
							} else {
								triggerMultipleIssuesInsertLinkFailureFlag(createdIssues);
							}
						})
						.finally(() => {
							triggerMultipleIssuesCreatedFlag(createdIssues, reloadOnClick, showReloadAction);
						});
				} else {
					triggerMultipleIssuesCreatedFlag(createdIssues, reloadOnClick, showReloadAction);
				}
				void removeAiBulkIssueList(createdIssueIDs);
				if (failedIssueIDs.length !== 0) {
					logAiIssueCreationFailed(failedIssueIDs.length);
					void setErrorMessage({
						createdCount: createdIssueIDs.length,
						failedCount: failedIssueIDs.length,
					});
				} else {
					onCreateAll();
				}
			},
			onFailure: (error: Error | unknown) => {
				logAiIssueCreationFailed(aiBulkIssuesList.length, error);
				void setErrorMessage({ createdCount: 0, failedCount: aiBulkIssuesList.length });
			},
		});
	}, [
		logCreateAllClick,
		setErrorMessage,
		createAllIssuesHandler,
		logMultipleIssueCreateSuccess,
		insertStorageFragmentRequestPayload,
		removeAiBulkIssueList,
		constructXmlModification,
		singleIssuesCreated,
		triggerSingleIssueInsertLinkFailureFlag,
		triggerMultipleIssuesInsertLinkFailureFlag,
		triggerMultipleIssuesCreatedFlag,
		reloadOnClick,
		logAiIssueCreationFailed,
		onCreateAll,
		aiBulkIssuesList.length,
	]);

	return (
		<Inline space="space.150" xcss={buttonGroupStyles}>
			<Button appearance="subtle" onClick={onClose} testId="bulk-create-button-group-cancel">
				<FormattedMessage {...messages.bulkCreateCancelFooterButton} />
			</Button>
			<Button
				appearance="primary"
				isDisabled={isCreateAllDisabled}
				isLoading={isCreateAllLoading}
				onClick={onCreateAllClick}
				testId="bulk-create-button-group-create-all"
			>
				<FormattedMessage {...messages.bulkCreateCreateAllFooterButton} />
			</Button>
		</Inline>
	);
};
