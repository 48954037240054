import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/new';
import { Box, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { messages } from './messages';
import { AiErrorImage } from './assets/AiIssueCreateErrorImage';
import type { AiIssueCreationProps } from './__types__/AiCreationError';

const aiErrorTryAgainTestId = 'ai-error-try-again-button';
const aiErrorCreateManuallyTestId = 'ai-error-create-manually-button';

const aiErrorContainerStyle = xcss({
	flexGrow: '1',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'flex-start',
	paddingInline: 'space.800',
});

const aiErrorDescriptionStyle = xcss({
	paddingBlock: 'space.300',
	lineHeight: '24px',
	fontWeight: 'font.weight.regular',
	color: 'color.text.subtle',
});

const buttonLayoutStyle = xcss({
	width: '100%',
	paddingBlock: 'space.050',
});

export const AiCreationError = ({
	creationSource,
	errorMessage,
	errorMessageValues,
	onTryAgain,
	onCreateManually,
}: AiIssueCreationProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendScreenEvent',
			data: {
				name: 'ai creation error screen',
				source: creationSource,
			},
		}).fire();
	}, [createAnalyticsEvent, creationSource]);

	return (
		<Box xcss={aiErrorContainerStyle}>
			<Box>
				<AiErrorImage />
			</Box>
			<Box testId="ai-error-description" xcss={aiErrorDescriptionStyle}>
				<FormattedMessage {...errorMessage} values={errorMessageValues} />
			</Box>
			{onTryAgain && (
				<Box xcss={buttonLayoutStyle}>
					<Button testId={aiErrorTryAgainTestId} onClick={onTryAgain}>
						<FormattedMessage {...messages.aiErrorTryAgainButton} />
					</Button>
				</Box>
			)}
			{onCreateManually && (
				<Box xcss={buttonLayoutStyle}>
					<Button testId={aiErrorCreateManuallyTestId} onClick={onCreateManually}>
						<FormattedMessage {...messages.aiErrorCreateManuallyButton} />
					</Button>
				</Box>
			)}
		</Box>
	);
};
