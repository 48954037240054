import { useCallback } from 'react';

import { useBulkCreateContext, useGetIssueType } from '../providers/BulkCreateContextProvider';
import type { CreateAllIssueResponse } from '../__types__/apiUtils';

import { useCreateBulkIssues } from './useBulkCreateIssues';

export const useCreateAllIssues = () => {
	const [{ aiBulkIssuesList, currentJiraProject }] = useBulkCreateContext();
	const { createBulkIssuesHandler, loading, response } = useCreateBulkIssues();
	const issueType = useGetIssueType();

	const createAllIssuesHandler = useCallback(
		async ({
			onComplete,
			onFailure,
		}: {
			onComplete: (response: CreateAllIssueResponse) => void;
			onFailure: (error: Error | unknown) => void;
		}) => {
			if (!currentJiraProject || aiBulkIssuesList.length === 0) {
				return;
			}

			const issueUpdates = aiBulkIssuesList.map((issue) => ({
				fields: {
					description: issue.description,
					issuetype: { id: issueType.id },
					project: { id: currentJiraProject.id },
					summary: issue.summary,
				},
			}));

			void createBulkIssuesHandler({
				issueBody: {
					issueUpdates,
				},
				onComplete: ({ issues: createdIssues, errors: failedIssuesResponse }) => {
					const failedIssueIDs = failedIssuesResponse.map(
						(errorItem) => aiBulkIssuesList?.[errorItem.failedElementNumber]?.id,
					);
					const createdIssueIDs = aiBulkIssuesList
						.filter((issue) => !failedIssueIDs.includes(issue.id))
						.map((issue) => issue.id);
					onComplete({ createdIssueIDs, createdIssues, failedIssueIDs });
				},
				onFailure,
			});
		},
		[currentJiraProject, aiBulkIssuesList, createBulkIssuesHandler, issueType.id],
	);

	return {
		createAllIssuesHandler,
		loading,
		response,
	};
};
