import { useEffect } from 'react';

import {
	AiStates,
	ErrorStates,
	useBulkCreateContext,
} from '../providers/BulkCreateContextProvider';
import { useIsConfigureLoading } from '../providers/BulkConfigureContextProvider';

import { useAIAgentStreamingData } from './useAIAgentStreamingData';

export const useAiBulkFetchIssuesList = () => {
	const [, { setAiState, setError }] = useBulkCreateContext();
	const { currentAiState } = useAIAgentStreamingData();
	const { isLoading } = useIsConfigureLoading();

	useEffect(() => {
		if (currentAiState === AiStates.IDLE && isLoading) {
			return;
		}
		void setAiState(currentAiState);
		if (currentAiState === AiStates.ERROR) {
			void setError(ErrorStates.ISSUE_GENERATION);
		}
	}, [currentAiState, setAiState, setError, isLoading]);
};
