import type { FollowUpObject, StreamError } from '../api/StreamMessage';

export enum AIAgentResponseState {
	Initialization = 'Initialization',
	AnswerPart = 'AnswerPart',
	FinalResponse = 'FinalResponse',
	FollowUp = 'FollowUp',
	Error = 'Error',
}

export type AIAgentStreamingState<TContent = string> =
	| {
			responseState: AIAgentResponseState.Initialization;
			content: TContent | null;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			responseState: AIAgentResponseState.AnswerPart;
			content: TContent;
			answerChunkIndex: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			responseState: AIAgentResponseState.FinalResponse;
			content: TContent;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent?: FollowUpObject[];
	  }
	| {
			responseState: AIAgentResponseState.FollowUp;
			content: TContent | null;
			answerChunkIndex?: number;
			error?: StreamError;
			followUpContent: FollowUpObject[];
	  }
	| {
			responseState: AIAgentResponseState.Error;
			content: TContent | null;
			answerChunkIndex?: number;
			error: StreamError;
			followUpContent?: FollowUpObject[];
	  };

export function getDefaultAIAgentStreamingState<
	TAIAgentStreamingRequest,
>(): AIAgentStreamingState<TAIAgentStreamingRequest> {
	return {
		content: null,
		responseState: AIAgentResponseState.Initialization,
	};
}
