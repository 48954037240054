import React, { useCallback } from 'react';

import { Inline } from '@atlaskit/primitives';
import CheckIcon from '@atlaskit/icon/core/migration/check-mark--check';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import EditFilledIcon from '@atlaskit/icon/core/migration/edit--edit-filled';
import { IconButton } from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';

import { useBulkCreateContext, useSetCurrentIssueId } from './providers/BulkCreateContextProvider';
import { useCreateIssue } from './utils/useCreateIssue';
import { useBulkCreateFlags } from './utils/useBulkCreateFlags';
import { useSetErrorMessage } from './providers/AiBulkNavigationContextProvider';
import { useBulkCreateLoggingEvents } from './utils/useBulkCreateLoggingEvents';

type BulkCreateIssueCardToolBarProps = {
	issueId: string;
};

export const BulkCreateIssueCardToolBar = ({ issueId }: BulkCreateIssueCardToolBarProps) => {
	const setCurrentIssueId = useSetCurrentIssueId();

	const [{ issueTypeHasRequiredFields }, { removeAiBulkIssue }] = useBulkCreateContext();
	const { createIssueHandler, loading: isCreateLoading } = useCreateIssue();
	const { triggerSingleIssueCreatedFlag } = useBulkCreateFlags();
	const setErrorMessage = useSetErrorMessage();
	const {
		logCreateSingleIssueClick,
		logSingleIssueCreateSuccess,
		logAiIssueCreationFailed,
		logAiResultRemoved,
	} = useBulkCreateLoggingEvents();

	const isCreateDisabled = issueTypeHasRequiredFields || isCreateLoading;

	const onAcceptClick = useCallback(
		(event) => {
			event.stopPropagation();
			// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
			logCreateSingleIssueClick();
			void setErrorMessage(null);
			void createIssueHandler({
				issueId,
				onComplete: ({ key: issueKey, summary }) => {
					// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
					logSingleIssueCreateSuccess(true);
					void removeAiBulkIssue(issueId);
					triggerSingleIssueCreatedFlag({ issueKey, summary });
				},
				onFailure: (error: Error | unknown) => {
					logAiIssueCreationFailed(1, error);
					void setErrorMessage({ createdCount: 0, failedCount: 1 });
				},
			});
		},
		[
			issueId,
			removeAiBulkIssue,
			createIssueHandler,
			triggerSingleIssueCreatedFlag,
			setErrorMessage,
			logCreateSingleIssueClick,
			logSingleIssueCreateSuccess,
			logAiIssueCreationFailed,
		],
	);

	const onDiscardClick = useCallback(
		(event) => {
			event.stopPropagation();
			logAiResultRemoved();
			void setErrorMessage(null);
			void removeAiBulkIssue(issueId);
		},
		[issueId, removeAiBulkIssue, setErrorMessage, logAiResultRemoved],
	);

	const onEditClick = useCallback(() => {
		void setErrorMessage(null);
		void setCurrentIssueId(issueId);
	}, [issueId, setCurrentIssueId, setErrorMessage]);

	return (
		<Inline alignBlock="center" space="space.050">
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<CheckIcon
						{...iconProps}
						color={token('color.icon')}
						LEGACY_size="medium"
						spacing="none"
					/>
				)}
				isDisabled={isCreateDisabled}
				label="Accept"
				onClick={onAcceptClick}
				testId="bulk-create-issue-card-accept-action"
			/>
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<CrossIcon
						{...iconProps}
						color={token('color.icon')}
						LEGACY_size="small"
						spacing="none"
					/>
				)}
				isDisabled={isCreateLoading}
				label="Discard"
				onClick={onDiscardClick}
				testId="bulk-create-issue-card-discard-action"
			/>
			<IconButton
				appearance="subtle"
				icon={(iconProps) => (
					<EditFilledIcon
						{...iconProps}
						color={token('color.icon')}
						LEGACY_size="small"
						spacing="none"
					/>
				)}
				isDisabled={isCreateLoading}
				label="Edit"
				onClick={onEditClick}
				testId="bulk-create-issue-card-edit-action"
			/>
		</Inline>
	);
};
