import {
	createStore,
	createStateHook,
	createActionsHook,
	createContainer,
	type StoreActionApi,
} from 'react-sweet-state';

import type { AvailableSite } from '@atlassian/gic-anywhere';

import type { JiraProject, JiraProjects } from '../__types__/apiUtils';

import type { IssueType } from './BulkCreateContextProvider';

type BulkConfigureState = {
	optionsAvailableSites: AvailableSite[] | null;
	optionsRecentProjects: JiraProjects | null;
	optionsAllProjects: JiraProjects | null;
	optionsIssueTypes: IssueType[] | null;
	selectedAvailableSite: AvailableSite | null;
	selectedProject: JiraProject | null;
	selectedIssueType: IssueType | null;
	loadingProjects: boolean;
	loadingIssueType: boolean;
	projectSelectorQuery: string;
	isJiraError: boolean;
};

const initialState: BulkConfigureState = {
	optionsAvailableSites: null,
	optionsRecentProjects: null,
	optionsAllProjects: null,
	optionsIssueTypes: null,
	selectedAvailableSite: null,
	selectedProject: null,
	selectedIssueType: null,
	loadingProjects: false,
	loadingIssueType: false,
	projectSelectorQuery: '',
	isJiraError: false,
};

const actions = {
	setOptionsAvailableSites:
		(optionsAvailableSites: AvailableSite[]) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsAvailableSites });
		},
	setOptionsRecentProjects:
		(optionsRecentProjects: JiraProjects) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsRecentProjects });
		},
	setOptionsAllProjects:
		(optionsAllProjects: JiraProjects) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsAllProjects });
		},
	setOptionsIssueTypes:
		(optionsIssueTypes: IssueType[]) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ optionsIssueTypes });
		},
	setSelectedAvailableSite:
		(selectedAvailableSite: AvailableSite) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({
				selectedAvailableSite,
				selectedProject: null,
				selectedIssueType: null,
				optionsRecentProjects: null,
				optionsAllProjects: null,
				optionsIssueTypes: null,
				isJiraError: false,
			});
		},
	setSelectedProject:
		(selectedProject: JiraProject) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ selectedProject, selectedIssueType: null, optionsIssueTypes: null });
		},
	setSelectedProjectIfEmpty:
		(newSelectedProject: JiraProject) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { selectedProject } = getState();
			if (!selectedProject) {
				setState({
					selectedProject: newSelectedProject,
					selectedIssueType: null,
					optionsIssueTypes: null,
				});
			}
		},
	setSelectedIssueType:
		(selectedIssueType: IssueType) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ selectedIssueType });
		},
	setSelectedIssueTypeIfEmpty:
		(newSelectedIssueType: IssueType) =>
		({ getState, setState }: StoreActionApi<BulkConfigureState>) => {
			const { selectedIssueType } = getState();
			if (!selectedIssueType) {
				setState({
					selectedIssueType: newSelectedIssueType,
				});
			}
		},
	setLoadingProjects:
		(loadingProjects: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ loadingProjects });
		},
	setLoadingIssueType:
		(loadingIssueType: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ loadingIssueType });
		},
	setProjectSelectorQuery:
		(projectSelectorQuery: string) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ projectSelectorQuery });
		},
	setIsJiraError:
		(isJiraError: boolean) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ isJiraError });
		},
	resetAll:
		(
			selectedAvailableSite: AvailableSite,
			selectedProject: JiraProject,
			selectedIssueType: IssueType,
		) =>
		({ setState }: StoreActionApi<BulkConfigureState>) => {
			setState({ selectedAvailableSite, selectedProject, selectedIssueType });
		},
};

const Store = createStore<BulkConfigureState, typeof actions>({
	initialState,
	actions,
	name: 'BulkConfigureState',
});

export const BulkConfigureContextContainer = createContainer(Store);

const useActions = createActionsHook(Store);

export const useAvailableSitesActions = () => {
	const { setOptionsAvailableSites, setSelectedAvailableSite } = useActions();
	return { setOptionsAvailableSites, setSelectedAvailableSite };
};

export const useAvailableSitesState = createStateHook(Store, {
	selector: ({ optionsAvailableSites, selectedAvailableSite }: BulkConfigureState) => ({
		optionsAvailableSites,
		selectedAvailableSite,
	}),
});

export const useProjectsActions = () => {
	const {
		setOptionsRecentProjects,
		setOptionsAllProjects,
		setSelectedProject,
		setSelectedProjectIfEmpty,
		setLoadingProjects,
		setProjectSelectorQuery,
		setIsJiraError,
	} = useActions();
	return {
		setOptionsRecentProjects,
		setOptionsAllProjects,
		setSelectedProject,
		setSelectedProjectIfEmpty,
		setLoadingProjects,
		setProjectSelectorQuery,
		setIsJiraError,
	};
};

export const useProjectsState = createStateHook(Store, {
	selector: ({
		optionsRecentProjects,
		optionsAllProjects,
		selectedProject,
		projectSelectorQuery,
	}: BulkConfigureState) => ({
		optionsRecentProjects,
		optionsAllProjects,
		selectedProject,
		projectSelectorQuery,
	}),
});

export const useIssueTypesActions = () => {
	const {
		setOptionsIssueTypes,
		setSelectedIssueType,
		setLoadingIssueType,
		setSelectedIssueTypeIfEmpty,
	} = useActions();
	return {
		setOptionsIssueTypes,
		setSelectedIssueType,
		setSelectedIssueTypeIfEmpty,
		setLoadingIssueType,
	};
};

export const useIssueTypesState = createStateHook(Store, {
	selector: ({ optionsIssueTypes, selectedIssueType }: BulkConfigureState) => ({
		optionsIssueTypes,
		selectedIssueType,
	}),
});

export const useIsConfigureLoading = createStateHook(Store, {
	selector: ({
		selectedAvailableSite,
		loadingProjects,
		loadingIssueType,
		isJiraError,
	}: BulkConfigureState) => ({
		isLoading: selectedAvailableSite == null || loadingProjects || loadingIssueType,
		isJiraError,
	}),
});

export const useResetConfigureState = () => {
	const { resetAll } = useActions();
	return resetAll;
};
