import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { Location } from 'history';

import { useSidePanelStateActions } from '@confluence/side-panel-state-container';

import { useWarningDialogStateActions } from '../providers/IssueCreateWarningDialogContextProvider';
import { useBulkCreateContext } from '../providers/BulkCreateContextProvider';

import { useBulkCreateLoggingEvents } from './useBulkCreateLoggingEvents';

export const useCreateWarningDialogNav = (onClose: () => void) => {
	const { hideSidePanel } = useSidePanelStateActions();
	const { showWarningDialog, hideWarningDialog } = useWarningDialogStateActions();
	const [{ aiBulkIssuesList }] = useBulkCreateContext();
	const { logAiInteractionDismissed } = useBulkCreateLoggingEvents();
	const [nextLocation, setNextLocation] = useState<Location>();
	const history = useHistory();

	const handleConfirm = useCallback(() => {
		// TODO: Distinguish between ai and non-ai after non-ai bulk create is implemented
		// TODO2: Use a more accurate way to check if the user have already seen any result before closing the side panel
		if (aiBulkIssuesList.length === 0) {
			logAiInteractionDismissed();
		}
		hideWarningDialog();
		if (nextLocation) {
			history.block(() => {});
			history.push(nextLocation.pathname);
		}
		hideSidePanel();
		onClose();
	}, [
		aiBulkIssuesList,
		hideSidePanel,
		hideWarningDialog,
		history,
		logAiInteractionDismissed,
		nextLocation,
		onClose,
	]);

	const handleCancel = useCallback(() => {
		hideWarningDialog();
	}, [hideWarningDialog]);

	useEffect(() => {
		const unblockNavigation = history.block((location) => {
			setNextLocation(location);
			showWarningDialog();
			return false;
		});

		return () => unblockNavigation && unblockNavigation();
	}, [history, showWarningDialog]);

	return {
		handleConfirm,
		handleCancel,
	};
};
